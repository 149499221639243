import Vue from 'vue';
import _ from 'lodash';
import {customTermFilter} from "../../../vue/filters/CustomTerm";
import {simplePopupFactory} from "shared/simple_popups/simplePopupFactory";
import CreateGoalCategoryPopup from "../goals/CreateGoalCategoryPopup";
import goal_categories_resource from "../goals/goal_categories_resource";
import RouterCheckAvailabilityMixin from "../../../vue/common_components/RouterCheckAvailabilityMixin";
import goal_filter_settings_resource from "./goal_filter_settings_resource";
import {CurrentUserClass} from "shared/CurrentUser";

const InitialFilter = {
    categories: [],
    owners: [],
    show_archived: false,
    show_my: true,
    show_shared: false,
};

function customizer(objValue, othValue) {
    if (_.isBoolean(objValue) && _.isBoolean(othValue) && objValue === othValue) {
        return true;
    }
    if (_.isArray(objValue) && _.isArray(othValue) && objValue.length === othValue.length) {
        return true;
    }
}

export default {
    mixins: [RouterCheckAvailabilityMixin],
    data() {
        return {
            targetUsersID: undefined,
            goalsListMainResource: undefined,
            loadMoreResource: undefined,
            availableCategories: [],
            categoriesForFilter: [],
            sharersNames: [],

            categories: [],

            searchQuery: '',
            filter: _.merge({}, InitialFilter)
        };
    },
    computed: {
        initialFilter(){
            return _.merge({}, InitialFilter);
        },
        filterIsActive() {
            return !_.isEqualWith(this.initialFilter, this.filter, customizer);
        },
        nothingFound() {
            return !this.categories.length && (this.filterIsActive || this.searchQuery);
        },
        noGoals() {
            return !this.categories.length && !this.filterIsActive && !this.searchQuery;
        }
    },
    methods: {
        loadGoals() {
            this.$loadingSpinner.show();
            this.goalsListMainResource(this.targetUsersID, this.filter, this.searchQuery)
                .then(resp => {
                    this.categories = resp.data;
                })
                .finally(this.$loadingSpinner.hide);
        },
        loadMoreInCategory(category) {
            let $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            this.loadMoreResource(this.targetUsersID, category.id, category.goals.length, 10, this.filter, this.searchQuery)
                .then(resp => {
                    category.goals = category.goals.concat(resp.data.results);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally($rootScope.hide_dimmer);
        },
        archiveGoal({goal, category}) {
            let GoalsListResource = Vue.getAngularModule('GoalsListResource');
            let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            let $rootScope = Vue.getAngularModule('$rootScope');
            let $q = Vue.getAngularModule('$q');
            simplePopupFactory.show_popup(`Archive ${customTermFilter('Goal')}`, `Are you sure you want to Archive this ${customTermFilter('Goal')}?`, `Archive ${customTermFilter('Goal')}`, 'Cancel')
                .then(y => {
                    $rootScope.show_dimmer();
                    return GoalsListResource.archive_goal(this.targetUsersID, goal.id);
                }, n => {
                    return $q.defer().promise;
                })
                .then(resp => {
                    if (this.filter.show_archived) {
                        goal.is_archived = true;
                    } else {
                        category.goals.splice(category.goals.indexOf(goal), 1);
                        category.count--;
                    }
                    Vue.notifications.success('Archived');
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => {
                    $rootScope.hide_dimmer();
                });
        },
        unArchiveGoal({goal, category}) {
            let GoalsListResource = Vue.getAngularModule('GoalsListResource');
            let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
            let $rootScope = Vue.getAngularModule('$rootScope');
            let $q = Vue.getAngularModule('$q');
            simplePopupFactory.show_popup(`Mark ${customTermFilter('Goal')} as In Progress`, 'Are you sure you want to mark this as \'In Progress\'?', 'Mark as In Progress', 'Cancel')
                .then(y => {
                    $rootScope.show_dimmer();
                    GoalsListResource.un_archive_goal(this.targetUsersID, goal.id);
                }, n => {
                    return $q.defer().promise;
                })
                .then(resp => {
                    goal.is_archived = false;
                    Vue.notifications.success('Marked as In Progress');
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(() => {
                    $rootScope.hide_dimmer();
                });
        },
        search(val) {
            this.searchQuery = val;
            this.loadGoals();
        },
        cancelSearch(val) {
            this.searchQuery = '';
            this.loadGoals();
        },
        async createGoal(category = null) {
            let GoalResource = Vue.getAngularModule('GoalResource');
            let goalSettingsFactory = Vue.getAngularModule('goalSettingsFactory');
            const goalData = await goalSettingsFactory.create_goal(this.getGoalInitialData(category), this.getNewGoalPopupParams()).catch(() => {
            });
            if (!goalData) {
                return;
            }
            this.$loadingSpinner.show();
            try {
                const {data} = await GoalResource.create_goal(goalData.goal_data);
                this.loadGoals();
            } catch (e) {
                this.$notifications.error(e);
                this.$loadingSpinner.hide();
            }
        },
        createGoalInCategory(category) {
            this.createGoal(category.id);
        },
        async createGoalCategory() {
            const {popupComponent, fEl, vm} = simplePopupFactory.mount_vue_popup(CreateGoalCategoryPopup);
            try {
                const res = await popupComponent.show();
                this.$loadingSpinner.show();
                try {
                    const {data} = await goal_categories_resource.create_category({
                        ...res,
                        owner: this.targetUsersID,
                    });
                    this.availableCategories.push(data);
                    this.categories.push(data);
                    this.categories = _.orderBy(this.categories, c => c.name);
                    console.log(this.categories);
                    this.$notifications.success('Category created');
                    this.$loadingSpinner.hide();
                } catch (e) {
                    this.$loadingSpinner.hide();
                    this.$notifications.error(e);
                }

            } catch (e) {
                console.log(e);
            } finally {
                vm?.$destroy();
            }
        },
        async editGoalCategory(category) {
            const {popupComponent, fEl, vm} = simplePopupFactory.mount_vue_popup(CreateGoalCategoryPopup);
            try {
                const res = await popupComponent.show(category);
                if (res === 'delete') {
                    this.$loadingSpinner.show();
                    try {
                        await goal_categories_resource.delete_category(category.id);
                        this.loadGoals();
                        this.$notifications.success('Category Deleted');
                    } catch (e) {
                        this.$loadingSpinner.hide();
                        this.$notifications.error(e);
                    }
                } else {
                    this.$loadingSpinner.show();
                    try {
                        const {data} = await goal_categories_resource.update_category(category.id, res);
                        const targetCategory = this.categories.filter(c => c.id === category.id)[0];
                        if (targetCategory) {
                            for (let k of Object.keys(data)) {
                                if (['name', 'video_description', 'audio_description', 'image'].includes(k)) {
                                    Vue.set(targetCategory, k, data[k]);
                                }
                            }
                        }
                        this.$notifications.success('Category Updated');
                        this.$loadingSpinner.hide();
                    } catch (e) {
                        this.$loadingSpinner.hide();
                        this.$notifications.error(e);
                    }
                }
            } catch (e) {
                console.log(e);
            } finally {
                vm?.$destroy();
            }
        },
        filterChanged(filterParams) {
            this.filter = filterParams;
            this.loadGoals();
        },
        openFilterPopup() {
            this.$refs.filterPopup.open();
        },
        async $_getMySavedFilterSettings() {
            const {data: filterSettings} = await goal_filter_settings_resource.get_settings(new CurrentUserClass().id);
            for (let k of Object.keys(filterSettings || {})) {
                Vue.set(this.filter, k, filterSettings[k]);
            }
        },
    }
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { transition: 0 },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("a", { attrs: { id: "close_popup" }, on: { click: _vm.close } }),
        _vm._v(" "),
        _c("h3", { staticClass: "modal-title" }, [
          _vm._v("Filter " + _vm._s(_vm._f("customTerm")("Goals"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          { staticClass: "btn btn-mm btn-default", on: { click: _vm.reset } },
          [_vm._v("Reset Filter")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-mm btn-default", on: { click: _vm.close } },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-mm btn-green",
            attrs: { disabled: !_vm.show_my && !_vm.show_shared },
            on: { click: _vm.apply },
          },
          [_vm._v("Apply")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-15-top" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "inline-block" },
            [
              _c("vue-switch", {
                staticClass: "vertical-middle",
                attrs: { id: "show_archived", name: "show_archived" },
                model: {
                  value: _vm.show_archived,
                  callback: function ($$v) {
                    _vm.show_archived = $$v
                  },
                  expression: "show_archived",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "vertical-middle" }, [
                _vm._v(
                  " Show Archived " + _vm._s(_vm._f("customTerm")("Goals"))
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allowMyFilters,
              expression: "allowMyFilters",
            },
          ],
          staticClass: "row margin-15-top",
        },
        [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("vue-switch", {
                  staticClass: "vertical-middle",
                  attrs: { id: "show_my", name: "show_my" },
                  model: {
                    value: _vm.show_my,
                    callback: function ($$v) {
                      _vm.show_my = $$v
                    },
                    expression: "show_my",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "vertical-middle" }, [
                  _vm._v(" Show My " + _vm._s(_vm._f("customTerm")("Goals"))),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { "margin-top": "15px" } }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "inline-block" },
            [
              _c("vue-switch", {
                staticClass: "vertical-middle",
                attrs: { id: "show_shared", name: "show_shared" },
                model: {
                  value: _vm.show_shared,
                  callback: function ($$v) {
                    _vm.show_shared = $$v
                  },
                  expression: "show_shared",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "vertical-middle" }, [
                _vm._v(" Show Shared " + _vm._s(_vm._f("customTerm")("Goals"))),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.usernameOptions.length
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("div", [
                  _vm._v(
                    "Filter by " +
                      _vm._s(_vm._f("customTerm")("Goal")) +
                      " Owner: "
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.owners.length,
                          expression: "owners.length",
                        },
                      ],
                      staticClass: "pointer",
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.resetOwnerFilter },
                    },
                    [_vm._v("(reset)")]
                  ),
                ]),
                _vm._v(" "),
                _c("multi-select", {
                  attrs: {
                    disabled: !_vm.show_shared,
                    options: _vm.usernameOptions,
                    size: "lg",
                    block: "",
                  },
                  model: {
                    value: _vm.owners,
                    callback: function ($$v) {
                      _vm.owners = $$v
                    },
                    expression: "owners",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.categoryOptions.length > 1
        ? _c("div", { staticClass: "row margin-15-top" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("div", [
                  _vm._v(
                    "Filter by " +
                      _vm._s(_vm._f("customTerm")("Goal")) +
                      " Category: "
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.categories.length,
                          expression: "categories.length",
                        },
                      ],
                      staticClass: "pointer",
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.resetCategoriesFilter },
                    },
                    [_vm._v("(reset)")]
                  ),
                ]),
                _vm._v(" "),
                _c("multi-select", {
                  attrs: {
                    options: _vm.categoryOptions,
                    size: "lg",
                    block: "",
                  },
                  model: {
                    value: _vm.categories,
                    callback: function ($$v) {
                      _vm.categories = $$v
                    },
                    expression: "categories",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }